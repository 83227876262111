import React from "react";

const AboutMe = () => {
    return (
        <div>
            {"Work in progress"}
        </div>
    )
}

export default AboutMe;